export const poolRadRaces = {

    0: "Monster",
    1: "Dwarf",
    2: "Elf",
    3: "Gnome",
    4: "Half-elf",
    5: "Halfling",
    6: "Half-orc",
    7: "Human",

}

export const genders = {
    0: "Male",
    1: "Female"
}

export const poolRadStatusCodes = {
    0: "Okay",
    1: "Animated",
    2: "Tempgone",
    3: "Running",
    4: "Unconscious",
    5: "Dying",
    6: "Dead",
    7: "Stoned",
    8: "Gone",
    
}

export const alignments = {

    0: "Lawful Good",
    1: "Lawful Neutral",
    2: "Lawful Evil",
    3: "Neutral Good",
    4: "True Neutral",
    5: "Neutral Evil",
    6: "Chaotic Good",
    7: "Chaotic Neutral",
    8: "Chaotic Evil"
}

export const poolRadSpells ={

    51: {spellName: "Bless", class: "Cleric", level: 1},
    52: {spellName: "Curse", class: "Cleric", level: 1},
    53: {spellName: "Cure Light Wounds", class: "Cleric", level: 1},
    54: {spellName: "Cause Light Wounds", class: "Cleric", level: 1},
    55: {spellName: "Detect Magic", class: "Cleric", level: 1},
    56: {spellName: "Protection from Evil", class: "Cleric", level: 1},
    57: {spellName: "Protection from Good", class: "Cleric", level: 1},
    58: {spellName: "Resist Cold", class: "Cleric", level: 1},
    59: {spellName: "Burning Hands", class: "Mage", level: 1},
    60: {spellName: "Charm Person", class: "Mage", level: 1},
    61: {spellName: "Detect Magic", class: "Mage", level: 1},
    62: {spellName: "Enlarge", class: "Mage", level: 1},
    63: {spellName: "Reduce", class: "Mage", level: 1},
    64: {spellName: "Friends", class: "Mage", level: 1},
    65: {spellName: "Magic Missile", class: "Mage", level: 1},
    66: {spellName: "Protection from Evil", class: "Mage", level: 1},
    67: {spellName: "Protection from Good", class: "Mage", level: 1},
    68: {spellName: "Read Magic", class: "Mage", level: 1},
    69: {spellName: "Shield", class: "Mage", level: 1},
    70: {spellName: "Shocking Grasp", class: "Mage", level: 1},
    71: {spellName: "Sleep", class: "Mage", level: 1},
    72: {spellName: "Find Traps", class: "Cleric", level: 2},
    73: {spellName: "Hold Person", class: "Cleric", level: 2},
    74: {spellName: "Resist Fire", class: "Cleric", level: 2},
    75: {spellName: "Silence, 15' Radius", class: "Cleric", level: 2},
    76: {spellName: "Slow Poison", class: "Cleric", level: 2},
    77: {spellName: "Snake Charm", class: "Cleric", level: 2},
    78: {spellName: "Spiritual Hammer", class: "Cleric", level: 2},
    79: {spellName: "Detect Invisibility", class: "Mage", level: 3},
    80: {spellName: "Invisibility", class: "Mage", level: 3},
    81: {spellName: "Knock", class: "Mage", level: 3},
    82: {spellName: "Mirror Image", class: "Mage", level: 3},
    83: {spellName: "Ray of Enfeeblement", class: "Mage", level: 3},
    84: {spellName: "Stinking Cloud", class: "Mage", level: 3},
    85: {spellName: "Strength", class: "Mage", level: 3},
    86: {spellName: "Animate Dead", class: "Cleric", level: 3},
    87: {spellName: "Cure Blindness", class: "Cleric", level: 3},
    88: {spellName: "Cause Blindness", class: "Cleric", level: 3},
    89: {spellName: "Cure Disease", class: "Cleric", level: 3},
    90: {spellName: "Cause Disease", class: "Cleric", level: 3},
    91: {spellName: "Dispel Magic", class: "Cleric", level: 3},
    92: {spellName: "Prayer", class: "Cleric", level: 3},
    93: {spellName: "Remove Curse", class: "Cleric", level: 3},
    94: {spellName: "Bestow Curse", class: "Cleric", level: 3},
    95: {spellName: "Blink", class: "Mage", level: 3},
    96: {spellName: "Dispel Magic", class: "Mage", level: 3},
    97: {spellName: "Fireball", class: "Mage", level: 3},
    98: {spellName: "Haste", class: "Mage", level: 3},
    99: {spellName: "Hold Person", class: "Mage", level: 3},
    100: {spellName: "Inivisibility, 10' Radius", class: "Mage", level: 3},
    101: {spellName: "Lightning Bolt", class: "Mage", level: 3},
    102: {spellName: "Protection from Evil, 10' Radius", class: "Mage", level: 3},
    103: {spellName: "Protection from Good, 10' Radius", class: "Mage", level: 3},
    104: {spellName: "Protection from Normal Missiles", class: "Mage", level: 3},
    105: {spellName: "Slow", class: "Mage", level: 3},
    106: {spellName: "Restoration", class: "Cleric", level: 7},
}

export const poolRadItemValues = {

    0: "",
    1: "Battle Axe",
    2: "Hand Axe",
    3: "Bardiche",
    4: "Bec de Corbin",
    5: "Bill Guisarme",
    6: "Bo Staff",
    7: "Club",
    8: "Dagger",
    9: "Dart",
    10: "Fauchard",
    11: "Fauchard-Fork",
    12: "Flail",
    13: "Military Fork",
    14: "Glaive",
    15: "Glaive-Guisarme",
    16: "Guisarme",
    17: "Guisarme-Voulge",
    18: "Halberd",
    19: "Lucern Hammer",
    20: "Hammer",
    21: "Javelin",
    22: "Jo Stick",
    23: "Mace",
    24: "Morning Star",
    25: "Partisan",
    26: "Military Pick",
    27: "Awl Pike",
    28: "Quarrel",
    29: "Ranseur",
    30: "Scimitar",
    31: "Spear",
    32: "Spetum",
    33: "Quarter Staff",
    34: "Bastard Sword",
    35: "Broad Sword",
    36: "Long Sword",
    37: "Short Sword",
    38: "Two-handed Sword",
    39: "Trident",
    40: "Voulge",
    41: "Composite Long Bow",
    42: "Composite Short Bow",
    43: "Long Bow",
    44: "Short Bow",
    45: "Heavy Crossbow",
    46: "Light Crossbow",
    47: "Sling",
    48: "Mail",
    49: "Armor",
    50: "Leather",
    51: "Padded",
    52: "Studded",
    53: "Ring",
    54: "Scale",
    55: "Chain",
    56: "Splint",
    57: "Banded",
    58: "Plate",
    59: "Shield",
    60: "Woods",
    61: "Arrow",
    64: "Potion",
    65: "Scroll",
    66: "Ring",
    67: "Rod",
    68: "Stave",
    69: "Wand",
    70: "Jug",
    71: "Amulet",
    72: "Apparatus",
    73: "Bags",
    74: "Beaker",
    75: "Boat",
    76: "Book",
    77: "Boots",
    78: "Bowl",
    79: "Bracers",
    80: "Brazier",
    81: "Brooch",
    82: "Broom",
    83: "Purse",
    84: "Candle",
    85: "Carpet",
    86: "Censer",
    87: "Chime",
    88: "Cloak",
    89: "Crystal",
    90: "Cube",
    91: "Cubic",
    92: "Fortress",
    93: "Decanter",
    94: "Deck",
    95: "Drums",
    96: "Dust",
    97: "Eyes",
    98: "Figurine",
    99: "Flask",
    100: "Gauntlets",
    101: "Gem",
    102: "Girdle",
    103: "Helm",
    104: "Horn",
    105: "Horseshoes",
    106: "Incense",
    107: "Stone",
    108: "Instrument",
    109: "Javelin",
    110: "Jewel",
    111: "Ointment",
    112: "Libram",
    113: "Lyre",
    114: "Manual",
    115: "Mattock",
    116: "Maul",
    117: "Medallion",
    118: "Mirror",
    121: "Pigment",
    122: "Pearl",
    123: "Periapt",
    124: "Phylactery",
    125: "Pipes",
    126: "Hole",
    127: "Token",
    128: "Robe",
    129: "Rope",
    130: "Rug",
    131: "Saw",
    132: "Scarab",
    133: "Spade",
    134: "Sphere",
    136: "Talisman",
    137: "Tome",
    138: "Trident",
    139: "Grimoire",
    140: "Well",
    141: "Wings",
    142: "Vial",
    143: "Lantern",
    145: "Oil",
    146: "10 ft. Rope",
    147: "50 ft. Rope",
    148: "Iron",
    149: "Thief Prickly Tools",
    150: "Iron Rations",
    151: "Standard Rations",
    152: "Holy Symbol",
    153: "Holy Water Vial",
    154: "Unholy Water Vial",
    155: "Barding",
    156: "Dragon",
    157: "Lightning",
    158: "Saddle",
    159: "Small Raft",
    160: "Cart",
    161: "Wagon",
    162: "+1",
    163: "+2",
    164: "+3",
    165: "+4",
    166: "+5",
    167: "of",
    169: "Cloak",
    170: "Displacement",
    171: "Torches",
    172: "Oil",
    173: "Speed",
    174: "Tapestry",
    175: "Bodily Health",
    176: "Copper",
    177: "Silver",
    178: "Electrum",
    179: "Gold",
    180: "Platinum",
    181: "Ointment",
    182: "Keoghtum's",
    183: "Sheet",
    184: "Strength",
    185: "Healing",
    186: "Holding",
    187: "Extra",
    188: "Gaseous Form",
    189: "Slipperiness",
    190: "Jewelled",
    191: "Flying",
    192: "Treasure Finding",
    193: "Fear",
    194: "Disappearance",
    195: "Statuette",
    196: "Fungus",
    197: "Chain",
    198: "Pendant",
    199: "Broach",
    200: "of Seeking",
    201: "-1",
    202: "-2",
    203: "-3",
    204: "Lightning Bolt",
    205: "Fire Resistance",
    206: "Magic Missiles",
    207: "Save",
    208: "Clerical Scroll",
    209: "Magic User Scroll",
    210: "With 1 Spell",
    211: "With 2 Spells",
    212: "With 3 Spells",
    213: "Protection Scroll",
    214: "Jewelry",
    215: "Fine",
    216: "Huge",
    217: "Bone",
    218: "Brass",
    219: "Key",
    220: "AC 2",
    221: "AC 6",
    222: "AC 4",
    223: "AC 3",
    224: "of Protection",
    225: "Paralyzation",
    226: "Ogre Power",
    227: "Invisibility",
    228: "Missiles",
    229: "Elvenkind",
    230: "Rotting",
    231: "Covered",
    232: "Efreeti",
    233: "Bottle",
    234: "Missile Attractor",
    235: "of Maglubiyet",
    236: "Secret Door & Trap Detection",
    237: "Gold Dragon Control",
    238: "Feather Falling",
    239: "Giant Strength",
    240: "Restoring Levels",
    241: "Flame Tongue",
    242: "Fireballs",
    243: "Spiritual Hammer",
    244: "Boulder",
    245: "Diamond",
    246: "Emerald",
    247: "Opal",
    248: "Sapphire",
    249: "of Tyr",
    250: "of Tempus",
    251: "of Sune",
    252: "Wooden",
    253: "+3 vs Undead",
    254: "Pass",
    255: "Cursed",


}

export const poolRadClassList ={

    0: "Cleric",
    // 1: "Druid",
    2: "Fighter",
    // 3: "Paladin",
    // 4: "Ranger",
    5: "Magic-user",
    6: "Thief",
    // 7: "Monk",
    8: "Cleric/Fighter",
    9: "Cleric/Fighter/Magic-user",
    // 10: "Cleric/Ranger",
    11: "Cleric/Magic-user",
    // 12: "Cleric/Thief",
    13: "Fighter/Magic-user",
    14: "Fighter/Thief",
    15: "Fighter/Magic-user/Thief",
    16: "Magic-user/Thief",
    17: "Monster",
}
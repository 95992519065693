export const silverBladesStatusCodes = {
    0: "Okay",
    1: "Animated",
    2: "Tempgone",
    3: "Running",
    4: "Unconscious",
    5: "Dying",
    6: "Dead",
    7: "Petrified",
    8: "Gone",
    
}

export const silverBladesRaces = {

    0: "Tribble",
    1: "Elf",
    2: "Half-Elf",
    3: "Dwarf",
    4: "Gnome",
    5: "Halfling",
    6: "Human",
    7: "Monster",

}

export const SilverBladesItemValues = {

    0: "",
    1: "Battle Axe",
    2: "Hand Axe",
    3: "Club",
    4: "Dagger",
    5: "Dart",
    6: "Hammer",
    7: "Javelin",
    8: "Mace",
    9: "Morning Star",
    10: "Military Pick",
    11: "Awl Pike",
    12: "Quarrel",
    13: "Scimitar",
    14: "Spear",
    15: "Quarter Staff",
    16: "Bastard Sword",
    17: "Broad Sword",
    18: "Long Sword",
    19: "Short Sword",
    20: "Two-handed sword",
    21: "Trident",
    22: "Composite Long Bow",
    23: "Composite Short Bow",
    24: "Long Bow",
    25: "Short Bow",
    26: "Fine",
    27: "Light Crossbow",
    28: "Sling",
    29: "Staff",
    30: "Arrows",
    31: "Leather",
    32: "Ring",
    33: "Scale",
    34: "Chain",
    35: "Banded",
    36: "Plate",
    37: "Shield",
    38: "Cler",
    39: "Scroll",
    40: "Mage",
    41: "Helm",
    42: "Belt",
    43: "Robe",
    44: "Cloak",
    45: "Boots",
    46: "Ring",
    47: "Mail",
    48: "Armor",
    49: "Of Prot",
    50: "Bracers",
    51: "Wand",
    52: "Elixir",
    53: "Potion",
    54: "Youth",
    55: "Ruby",
    56: "Boulder",
    57: "Dragon Breath",
    58: "Cloak",
    59: "Eyes",
    60: "Drow",
    61: "Elven Chain",
    62: "Ice Storm",
    63: "Sapphire",
    64: "Emerald",
    65: "Wizardry",
    66: "Hornet's Nest",
    67: "Fire Resistance",
    68: "Stone",
    69: "Good Luck",
    70: "Flail",
    71: "Halberd",
    72: "Gauntlets",
    78: "Ogre Power",
    79: "Girdle",
    80: "Giant Strength",
    81: "Mirror",
    82: "Necklace",
    83: "Dragon",
    84: "Vs Giants",
    // 85: "85",
    // 86: "86",
    87: "Diamond",
    88: "Lightning",
    89: "Fireballs",
    90: "of",
    91: "Vulnerability",
    92: "Speed",
    93: "Silver",
    94: "Extra",
    95: "Healing",
    96: "Charming",
    97: "Fear",
    98: "Magic Missiles",
    99: "Missiles",
    100: "1 Spell",
    101: "2 Spells",
    102: "3 Spells",
    103: "Paralyzation",
    104: "Invisibility",
    105: "Cute Yellow Canary",
    106: "AC 10",
    107: "AC 6",
    108: "AC 4",
    109: "AC 3",
    110: "AC 2",
    111: "+1",
    112: "+2",
    113: "+3",
    114: "+4",
    115: "+5",
    116: "-1",
    117: "-2",
    118: "-3",
    121: "Spiritual",
    122: "Gem",
    123: "Jewelry",


}

export const SilverBladesItemTypes = {

    0: "",
    1: "Battle Axe",
    4: "Dagger",
    5: "Dart",
    8: "Mace",
    9: "Morning Star",
    12: "Quarrel",
    13: "Scimitar",
    15: "Quarter Staff",
    18: "Long Sword",
    20: "Two-handed sword",
    21: "Trident",
    22: "Composite Long Bow",
    26: "Long Bow",
    27: "Light Crossbow",
    29: "Sling?",
    30: "Arrows",
    31: "Leather",
    35: "Banded",
    36: "Plate",
    37: "Shield",
    38: "Scroll",
    39: "Scroll?",
    42: "Girdle",
    45: "Boots",
    46: "Ring",
    47: "Magical Item",
    50: "Bracers",
    52: "Wand",
    58: "Cloak",
    70: "Flail",
    71: "Halberd",
    72: "Gauntlets",
    78: "Ogre Power",
    90: "of",


}




export const silverBladesSpellList = {
    113: {spellName: "Bless", class: "Cleric", level: 1},
    114: {spellName: "Curse", class: "Cleric", level: 1},
    115: {spellName: "Cure Light Wounds", class: "Cleric", level: 1},
    116: {spellName: "Cause Light Wounds", class: "Cleric", level: 1},
    117: {spellName: "Detect Magic", class: "Cleric", level: 1},
    118: {spellName: "Protection from Evil", class: "Cleric", level: 1},
    119: {spellName: "Protection from Good", class: "Cleric", level: 1},
    120: {spellName: "Resist Cold", class: "Cleric", level: 1},
    121: {spellName: "Burning Hands", class: "Mage", level: 1},
    122: {spellName: "Charm Person", class: "Mage", level: 1},
    123: {spellName: "Detect Magic", class: "Mage", level: 1},
    124: {spellName: "Enlarge", class: "Mage", level: 1},
    125: {spellName: "Reduce", class: "Mage", level: 1},
    126: {spellName: "Friends", class: "Mage", level: 1},
    127: {spellName: "Magic Missile", class: "Mage", level: 1},
    128: {spellName: "Protection from Evil", class: "Mage", level: 1},
    129: {spellName: "Protection from Good", class: "Mage", level: 1},
    130: {spellName: "Read Magic", class: "Mage", level: 1},
    131: {spellName: "Shield", class: "Mage", level: 1},
    132: {spellName: "Shocking Grasp", class: "Mage", level: 1},
    133: {spellName: "Sleep", class: "Mage", level: 1},
    134: {spellName: "Find Traps", class: "Cleric", level: 2},
    135: {spellName: "Hold Person", class: "Cleric", level: 2},
    136: {spellName: "Resist Fire", class: "Cleric", level: 2},
    137: {spellName: "Silence 15' Radius", class: "Cleric", level: 2},
    138: {spellName: "Slow Poison", class: "Cleric", level: 2},
    139: {spellName: "Snake Charm", class: "Cleric", level: 2},
    140: {spellName: "Spiritual Hammer", class: "Cleric", level: 2},
    141: {spellName: "Detect Invisibility", class: "Mage", level: 2},
    142: {spellName: "Invisibility", class: "Mage", level: 2},
    143: {spellName: "Knock", class: "Mage", level: 2},
    144: {spellName: "Mirror Image", class: "Mage", level: 2},
    145: {spellName: "Ray of Enfeeblement", class: "Mage", level: 2},
    146: {spellName: "Stinking Cloud", class: "Mage", level: 2},
    147: {spellName: "Strength", class: "Mage", level: 2},
    148: {spellName: "Heal", class: "Cleric", level: 6},
    149: {spellName: "Cure Blindness", class: "Cleric", level: 3},
    150: {spellName: "Cause Blindness", class: "Cleric", level: 3},
    151: {spellName: "Cure Disease", class: "Cleric", level: 3},
    152: {spellName: "Cause Disease", class: "Cleric", level: 3},
    153: {spellName: "Dispel Magic", class: "Cleric", level: 3},
    154: {spellName: "Prayer", class: "Cleric", level: 3},
    155: {spellName: "Remove Curse", class: "Cleric", level: 3},
    156: {spellName: "Bestow Curse", class: "Cleric", level: 3},
    157: {spellName: "Blink", class: "Mage", level: 3},
    158: {spellName: "Dispel Magic", class: "Mage", level: 3},
    159: {spellName: "Fireball", class: "Mage", level: 3},
    160: {spellName: "Haste", class: "Mage", level: 3},
    161: {spellName: "Hold Person", class: "Mage", level: 3},
    162: {spellName: "Invisibilty 10' Radius", class: "Mage", level: 3},
    163: {spellName: "Lightning Bolt", class: "Mage", level: 3},
    164: {spellName: "Protection from Evil, 10' Radius", class: "Mage", level: 3},
    165: {spellName: "Protection from Good, 10' Radius", class: "Mage", level: 3},
    166: {spellName: "Protection from Normal Missiles", class: "Mage", level: 3},
    167: {spellName: "Slow", class: "Mage", level: 3},
    168: {spellName: "Harm", class: "Cleric", level: 6},
    169: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    170: {spellName: "Cure Serious Wounds", class: "Cleric", level: 4},
    171: {spellName: "Spell", class: "Cleric", level: 0},
    172: {spellName: "Spell", class: "Cleric", level: 0},
    173: {spellName: "Spell", class: "Cleric", level: 0},
    174: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    175: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    176: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    177: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    178: {spellName: "Cause Serious Wounds", class: "Cleric", level: 4},
    179: {spellName: "Neutralize Poison", class: "Cleric", level: 4},
    180: {spellName: "Poison", class: "Cleric", level: 4},
    181: {spellName: "Protection from Evil 10'", class: "Cleric", level: 4},
    182: {spellName: "Sticks to Snakes", class: "Cleric", level: 4},
    183: {spellName: "Cure Critial Wounds", class: "Cleric", level: 5},
    184: {spellName: "Cause Critical Wounds", class: "Cleric", level: 5},
    185: {spellName: "Dispel Evil", class: "Cleric", level: 5},
    186: {spellName: "Flame Strike", class: "Cleric", level: 5},
    187: {spellName: "Raise Dead", class: "Cleric", level: 5},
    188: {spellName: "Slay Living", class: "Cleric", level: 5},
    189: {spellName: "Detect Magic", class: "Druid", level: 1},
    190: {spellName: "Entangle", class: "Druid", level: 1},
    191: {spellName: "Faerie Fire", class: "Druid", level: 1},
    192: {spellName: "Invisibility to Animals", class: "Druid", level: 1},
    193: {spellName: "Charm Monsters", class: "Mage", level: 4},
    194: {spellName: "Confusion", class: "Mage", level: 4},
    195: {spellName: "Dimension Door", class: "Mage", level: 4},
    196: {spellName: "Fear", class: "Mage", level: 4},
    197: {spellName: "Fire Shield", class: "Mage", level: 4},
    198: {spellName: "Fumble", class: "Mage", level: 4},
    199: {spellName: "Ice Storm", class: "Cleric", level: 4},
    200: {spellName: "Minor Globe of Invulnerability", class: "Mage", level: 4},
    201: {spellName: "Remove Curse", class: "Mage", level: 4},
    202: {spellName: "Bestow Curse", class: "Mage", level: 4},
    203: {spellName: "Cloud Kill", class: "Mage", level: 5},
    204: {spellName: "Cone of Cold", class: "Mage", level: 5},
    205: {spellName: "Feeblemind", class: "Mage", level: 5},
    206: {spellName: "Hold Monsters", class: "Mage", level: 5},
    207: {spellName: "Spell", class: "UNKNOWN", level: 0},
    208: {spellName: "Spell", class: "UNKNOWN", level: 0},
    209: {spellName: "Spell", class: "UNKNOWN", level: 0},
    210: {spellName: "Spell", class: "UNKNOWN", level: 0},
    211: {spellName: "Spell", class: "UNKNOWN", level: 0},
    212: {spellName: "Spell", class: "UNKNOWN", level: 0},
    213: {spellName: "Spell", class: "UNKNOWN", level: 0},
    214: {spellName: "Spell", class: "UNKNOWN", level: 0},
    215: {spellName: "Spell", class: "UNKNOWN", level: 0},
    216: {spellName: "Spell", class: "UNKNOWN", level: 0},
    217: {spellName: "Spell", class: "UNKNOWN", level: 0},
    218: {spellName: "Spell", class: "UNKNOWN", level: 0},
    219: {spellName: "Spell", class: "UNKNOWN", level: 0},
    220: {spellName: "Spell", class: "UNKNOWN", level: 0},
    221: {spellName: "Spell", class: "UNKNOWN", level: 0},
    222: {spellName: "Death Spell", class: "Mage", level: 6},
    223: {spellName: "Disintegrate", class: "Mage", level: 6},
    224: {spellName: "Globe of Invulnerability", class: "Mage", level: 6},
    225: {spellName: "Stone to Flesh", class: "Mage", level: 6},
    226: {spellName: "Flesh to Stone", class: "Mage", level: 6},
    227: {spellName: "Delayed Blast Fireball", class: "Mage", level: 7},
    228: {spellName: "Mass Invisibility", class: "Mage", level: 7},
    229: {spellName: "Power Word Stun", class: "Mage", level: 7},
    230: {spellName: "UNKNOWN", class: "UNKNOW", level: 0},
    231: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
}

export const silverBladesClassList ={

    0: "Cleric",
    1: "Druid",
    2: "Fighter",
    3: "Paladin",
    4: "Ranger",
    5: "Magic-user",
    6: "Thief",
    7: "Monk",
    8: "Cleric/Fighter",
    9: "Cleric/Fighter/Magic-user",
    10: "Cleric/Ranger",
    11: "Cleric/Magic-user",
    12: "Cleric/Thief",
    13: "Fighter/Magic-user",
    14: "Fighter/Thief",
    15: "Fighter/Magic-user/Thief",
    16: "Magic-user/Thief",
    17: "Monster",
}
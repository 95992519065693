export const podStatusCodes = {
    0: "Okay",
    1: "Animated",
    2: "Tempgone",
    3: "Running",
    4: "Unconscious",
    5: "Dying",
    6: "Dead",
    7: "Stoned",
    8: "Gone",
    
}

export const podRaces = {

    0: "Elf",
    1: "Half-elf",
    2: "Dwarf",
    3: "Gnome",
    4: "Halfling",
    5: "Human",
    6: "Monster",

}

export const podSpellList = {
    179: {spellName: "Bless", class: "Cleric", level: 1},
    180: {spellName: "Curse", class: "Cleric", level: 1},
    181: {spellName: "Cure Light Wounds", class: "Cleric", level: 1},
    182: {spellName: "Cause Light Wounds", class: "Cleric", level: 1},
    183: {spellName: "Detect Magic", class: "Cleric", level: 1},
    184: {spellName: "Protection from Evil", class: "Cleric", level: 1},
    185: {spellName: "Protection from Good", class: "Cleric", level: 1},
    186: {spellName: "Resist Cold", class: "Cleric", level: 1},
    187: {spellName: "Burning Hands", class: "Mage", level: 1},
    188: {spellName: "Charm Person", class: "Mage", level: 1},
    189: {spellName: "Detect Magic", class: "Mage", level: 1},
    190: {spellName: "Enlarge", class: "Mage", level: 1},
    191: {spellName: "Reduce", class: "Mage", level: 1},
    192: {spellName: "Friends", class: "Mage", level: 1},
    193: {spellName: "Magic Missile", class: "Mage", level: 1},
    194: {spellName: "Protection from Evil", class: "Mage", level: 1},
    195: {spellName: "Protection from Good", class: "Mage", level: 1},
    196: {spellName: "Read Magic", class: "Mage", level: 1},
    197: {spellName: "Shield", class: "Mage", level: 1},
    198: {spellName: "Shocking Grasp", class: "Mage", level: 1},
    199: {spellName: "Sleep", class: "Mage", level: 1},
    200: {spellName: "Find Traps", class: "Cleric", level: 2},
    201: {spellName: "Hold Person", class: "Cleric", level: 2},
    202: {spellName: "Resist Fire", class: "Cleric", level: 2},
    203: {spellName: "Silence 15' Radius", class: "Cleric", level: 2},
    204: {spellName: "Slow Poison", class: "Cleric", level: 2},
    205: {spellName: "Snake Charm", class: "Cleric", level: 2},
    206: {spellName: "Spiritual Hammer", class: "Cleric", level: 2},
    207: {spellName: "Detect Invisibility", class: "Mage", level: 2},
    208: {spellName: "Invisibility", class: "Mage", level: 2},
    209: {spellName: "Knock", class: "Mage", level: 2},
    210: {spellName: "Mirror Image", class: "Mage", level: 2},
    211: {spellName: "Ray of Enfeeblement", class: "Mage", level: 2},
    212: {spellName: "Stinking Cloud", class: "Mage", level: 2},
    213: {spellName: "Strength", class: "Mage", level: 2},
    214: {spellName: "Heal", class: "Cleric", level: 6},
    215: {spellName: "Cure Blindness", class: "Cleric", level: 3},
    216: {spellName: "Cause Blindness", class: "Cleric", level: 3},
    217: {spellName: "Cure Disease", class: "Cleric", level: 3},
    218: {spellName: "Cause Disease", class: "Cleric", level: 3},
    219: {spellName: "Dispel Magic", class: "Cleric", level: 3},
    220: {spellName: "Prayer", class: "Cleric", level: 3},
    221: {spellName: "Remove Curse", class: "Cleric", level: 3},
    222: {spellName: "Bestow Curse", class: "Cleric", level: 3},
    223: {spellName: "Blink", class: "Mage", level: 3},
    224: {spellName: "Dispel Magic", class: "Mage", level: 3},
    225: {spellName: "Fireball", class: "Mage", level: 3},
    226: {spellName: "Haste", class: "Mage", level: 3},
    227: {spellName: "Hold Person", class: "Mage", level: 3},
    228: {spellName: "Invisibilty 10' Radius", class: "Mage", level: 3},
    229: {spellName: "Lightning Bolt", class: "Mage", level: 3},
    230: {spellName: "Protection from Evil, 10' Radius", class: "Mage", level: 3},
    231: {spellName: "Protection from Good, 10' Radius", class: "Mage", level: 3},
    232: {spellName: "Protection from Normal Missiles", class: "Mage", level: 3},
    233: {spellName: "Slow", class: "Mage", level: 3},
    234: {spellName: "Harm", class: "Cleric", level: 6},
    235: {spellName: "UNKNOWN", class: "UNKNOWN", level: 4},
    236: {spellName: "Cure Serious Wounds", class: "Cleric", level: 4},
    237: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    238: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    239: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    240: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    241: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    242: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    243: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    244: {spellName: "Cause Serious Wounds", class: "Cleric", level: 4},
    245: {spellName: "Neutralize Poison", class: "Cleric", level: 4},
    246: {spellName: "Poison", class: "Cleric", level: 4},
    247: {spellName: "Protection from Evil 10'", class: "Cleric", level: 4},
    248: {spellName: "Sticks to Snakes", class: "Cleric", level: 4},
    249: {spellName: "Cure Critial Wounds", class: "Cleric", level: 5},
    250: {spellName: "Cause Critical Wounds", class: "Cleric", level: 5},
    251: {spellName: "Dispel Evil", class: "Cleric", level: 5},
    252: {spellName: "Flame Strike", class: "Cleric", level: 5},
    253: {spellName: "Raise Dead", class: "Cleric", level: 5},
    254: {spellName: "Slay Living", class: "Cleric", level: 5},
    255: {spellName: "Detect Magic", class: "Druid", level: 1},
    256: {spellName: "Entangle", class: "Druid", level: 1},
    257: {spellName: "Faerie Fire", class: "Druid", level: 1},
    258: {spellName: "Invisibility to Animals", class: "Druid", level: 1},
    259: {spellName: "Charm Monsters", class: "Mage", level: 4},
    260: {spellName: "Confusion", class: "Mage", level: 4},
    261: {spellName: "Dimension Door", class: "Mage", level: 4},
    262: {spellName: "Fear", class: "Mage", level: 4},
    263: {spellName: "Fire Shield", class: "Mage", level: 4},
    264: {spellName: "Fumble", class: "Mage", level: 4},
    265: {spellName: "Ice Storm", class: "Mage", level: 4},
    266: {spellName: "Minor Globe of Invulnerability", class: "Mage", level: 4},
    267: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    268: {spellName: "Barkskin", class: "Cleric", level: 0},
    269: {spellName: "Cloud Kill", class: "Mage", level: 5},
    270: {spellName: "Cone of Cold", class: "Mage", level: 5},
    271: {spellName: "Feeblemind", class: "Mage", level: 5},
    272: {spellName: "Hold Monsters", class: "Mage", level: 5},
    273: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    274: {spellName: "Charm Person or Mammal", class: "Druid", level: 0},
    275: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    276: {spellName: "Cure Light Wounds", class: "Druid", level: 0},
    277: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    278: {spellName: "Bestow Curse", class: "Mage", level: 4},
    279: {spellName: "Blade Barrier", class: "Cleric", level: 6},
    280: {spellName: "Destruction", class: "Cleric", level: 7},
    281: {spellName: "Energy Drain", class: "Cleric", level: 7},
    282: {spellName: "Resurrection", class: "Cleric", level: 7},
    283: {spellName: "Restoration", class: "Cleric", level: 7},
    284: {spellName: "Cure Disease", class: "Druid", level: 3},
    285: {spellName: "Neutralize Poison", class: "Druid", level: 3},
    286: {spellName: "Hold Animal", class: "Druid", level: 3},
    287: {spellName: "Protection from Fire", class: "Druid", level: 3},
    288: {spellName: "Death Spell", class: "Mage", level: 6},
    289: {spellName: "Disintegrate", class: "Mage", level: 6},
    290: {spellName: "Globe of Invulnerability", class: "Mage", level: 6},
    291: {spellName: "Stone to Flesh", class: "Mage", level: 6},
    292: {spellName: "Flesh to Stone", class: "Mage", level: 6},
    293: {spellName: "Delayed Blast Fireball", class: "Mage", level: 7},
    294: {spellName: "Mass Invisibility", class: "Mage", level: 7},
    295: {spellName: "Power Word Stun", class: "Mage", level: 7},
    296: {spellName: "Fire Touch", class: "Mage", level: 5},
    297: {spellName: "Iron Skin", class: "Mage", level: 5},
    298: {spellName: "Mass Charm", class: "Mage", level: 8},
    299: {spellName: "Otto's Irresistible Dance", class: "Mage", level: 8},
    300: {spellName: "Mind Blank", class: "Mage", level: 8},
    301: {spellName: "Power Word: Blind", class: "Mage", level: 8},
    302: {spellName: "Meteor Swarm", class: "Mage", level: 9},
    303: {spellName: "Power Word: Kill", class: "Mage", level: 9},
    304: {spellName: "Monster Summoning", class: "Mage", level: 9},

}

export const podClassList ={

    0: "Cleric",
    1: "Druid",
    2: "Fighter",
    3: "Paladin",
    4: "Ranger",
    5: "Magic-user",
    6: "Thief",
    7: "Monk",
    8: "Cleric/Fighter",
    9: "Cleric/Fighter/Magic-user",
    10: "Cleric/Ranger",
    11: "Cleric/Magic-user",
    12: "Cleric/Thief",
    13: "Fighter/Magic-user",
    14: "Fighter/Thief",
    15: "Fighter/Magic-user/Thief",
    16: "Magic-user/Thief",
    17: "Monster",
}

export const podItemValues = {

    0: "",
    1: "Battle Axe",
    2: "Hand Axe",
    3: "Club",
    4: "Dagger",
    5: "Dart",
    6: "Hammer",
    7: "Javelin",
    8: "Mace",
    9: "Morning Star",
    10: "Military Pick",
    11: "Awl Pike",
    12: "Bolt",
    13: "Scimitar",
    14: "Spear",
    15: "Quarter Staff",
    16: "Bastard Sword",
    17: "Broad Sword",
    18: "Long Sword",
    19: "Short Sword",
    20: "Two-handed sword",
    21: "Trident",
    22: "Composite Long Bow",
    23: "Composite Short Bow",
    24: "Long Bow",
    25: "Short Bow",
    26: "Fine",
    27: "Light Crossbow",
    28: "Sling",
    29: "Staff",
    30: "Arrow",
    31: "Leather",
    32: "Ring",
    33: "Scale",
    34: "Chain",
    35: "Banded",
    36: "Plate",
    37: "Shield",
    38: "Cleric",
    39: "Scroll",
    40: "Mage",
    41: "Helm",
    42: "Belt",
    43: "Robe",
    44: "Cloak",
    45: "Boots",
    46: "Ring",
    47: "Mail",
    48: "Armor",
    49: "Of Prot",
    50: "Bracers",
    51: "Wand",
    52: "Elixir",
    53: "Potion",
    54: "Youth",
    55: "Ruby",
    56: "Boulder",
    57: "Dragon Breath",
    58: "Displacement",
    59: "Eyes",
    60: "Drow",
    61: "Elfin Chain",
    62: "Ice Storm",
    63: "Sapphire",
    64: "Emerald",
    65: "Wizardry",
    66: "Hornet's Nest",
    67: "Fire Resistance",
    68: "Stone",
    69: "Good Luck",
    70: "Flail",
    71: "Halberd",
    72: "Gauntlets",
    78: "Ogre Power",
    79: "Girdle",
    80: "Giant Strength",
    81: "Mirror",
    82: "Necklace",
    83: "Dragon",
    84: "Vs Giants",
    85: "Vorpal",
    86: "Cold Resistance",
    87: "Diamond",
    88: "Lightning",
    89: "Fireballs",
    90: "of",
    91: "Vulnerability",
    92: "Speed",
    93: "Silver",
    94: "Extra",
    95: "Healing",
    96: "Charming",
    97: "Fear",
    98: "Magic Missiles",
    99: "Missiles",
    100: "1 Spell",
    101: "2 Spells",
    102: "3 Spells",
    103: "Paralyzation",
    104: "Invisibility",
    105: "Cute Yellow Canary",
    106: "AC 10",
    107: "AC 6",
    108: "AC 4",
    109: "AC 3",
    110: "AC 2",
    111: "+1",
    112: "+2",
    113: "+3",
    114: "+4",
    115: "+5",
    116: "-1",
    117: "-2",
    118: "-3",
    121: "Spiritual",
    122: "Gem",
    123: "Jewelry",
    124: "Blinking",
    125: "From",
    126: "Evil",
  
}
export const azureStatusCodes = {
    0: "Okay",
    1: "Animated",
    2: "Tempgone",
    3: "Running",
    4: "Unconscious",
    5: "Dying",
    6: "Dead",
    7: "Stoned",
    8: "Gone",
    
}

export const azureRaces = {

    0: "Monster",
    1: "Dwarf",
    2: "Elf",
    3: "Gnome",
    4: "Half-elf",
    5: "Halfling",
    6: "Half-orc",
    7: "Human",

}

export const azureItemValues = {

    0: "",
    1: "Battle Axe",
    2: "Hand Axe",
    3: "Bardiche",
    4: "Bec de Corbin",
    5: "Bill Guisarme",
    6: "Bo Staff",
    7: "Club",
    8: "Dagger",
    9: "Dart",
    10: "Fauchard",
    11: "Fauchard-Fork",
    12: "Flail",
    13: "Military Fork",
    14: "Glaive",
    15: "Glaive-Guisarme",
    16: "Guisarme",
    17: "Guisarme-Voulge",
    18: "Halberd",
    19: "Lucern Hammer",
    20: "Hammer",
    21: "Javelin",
    22: "Jo Stick",
    23: "Mace",
    24: "Morning Star",
    25: "Partisan",
    26: "Military Pick",
    27: "Awl Pike",
    28: "Quarrel",
    29: "Ranseur",
    30: "Scimitar",
    31: "Spear",
    32: "Spetum",
    33: "Quarter Staff",
    34: "Bastard Sword",
    35: "Broad Sword",
    36: "Long Sword",
    37: "Short Sword",
    38: "Two-handed Sword",
    39: "Trident",
    40: "Voulge",
    41: "Composite Long Bow",
    42: "Composite Short Bow",
    43: "Long Bow",
    44: "Short Bow",
    45: "Heavy Crossbow",
    46: "Light Crossbow",
    47: "Sling",
    48: "Mail",
    49: "Armor",
    50: "Leather",
    51: "Padded",
    52: "Studded",
    53: "Ring",
    54: "Scale",
    55: "Chain",
    56: "Splint",
    57: "Banded",
    58: "Plate",
    59: "Shield",
    60: "Woods",
    61: "Arrow",
    64: "Potion",
    65: "Scroll",
    66: "Ring",
    67: "Rod",
    68: "Stave",
    69: "Wand",
    70: "Jug",
    71: "Amulet",
    72: "Dragon Breath",
    73: "Bag",
    74: "Defoliation",
    75: "Ice Storm",
    76: "Book",
    77: "Boots",
    78: "Hornet's Nest",
    79: "Bracers",
    80: "Piercing",
    81: "Brooch",
    82: "Elfin Chain",
    83: "Wizardry",
    84: "AC 10",
    85: "Dexterity",
    86: "Fumbling",
    87: "Chime",
    88: "Cloak",
    89: "Crystal",
    90: "Cube",
    91: "Cubic",
    92: "The Dwarves",
    93: "Decanter",
    94: "Gloves",
    95: "Drums",
    96: "Dust",
    97: "Thievery",
    98: "Hat",
    //left off here
    99: "Flask",
    100: "Gauntlets",
    101: "Gem",
    102: "Girdle",
    103: "Helm",
    104: "Horn",
    105: "Stupidity",
    106: "Incense",
    107: "Stone",
    108: "Ioun Stone",
    109: "Javelin",
    110: "Jewel",
    111: "Ointment",
    112: "Pale Blue",
    113: "Scarlet and",
    114: "Manual",
    115: "Incandescent",
    116: "Deep Red",
    117: "Pink",
    118: "Mirror",
    121: "Blue",
    122: "Pearl",
    123: "Powerlessness",
    124: "Vermin",
    125: "Pipes",
    126: "Hole",
    127: "Dragon Slayer",
    128: "Robe",
    129: "Rope",
    130: "Frost Band",
    131: "Berserker",
    132: "Scarab",
    133: "Spade",
    134: "Sphere",
    136: "Talisman",
    137: "Tome",
    138: "Trident",
    139: "Grimoire",
    140: "Well",
    141: "Wings",
    142: "Vial",
    143: "Lantern",
    145: "Flask of Oil",
    146: "10 ft. Pole",
    147: "50 ft. Rope",
    148: "Iron",
    149: "Thief Prickly Tools",
    150: "Iron Rations",
    151: "Standard Rations",
    152: "Holy Symbol",
    153: "Holy Water Vial",
    154: "Unholy Water Vial",
    155: "Barding",
    156: "Dragon",
    157: "Lightning",
    158: "Saddle",
    159: "Staff",
    160: "Drow",
    161: "Wagon",
    162: "+1",
    163: "+2",
    164: "+3",
    165: "+4",
    166: "+5",
    167: "of",
    169: "Cloak",
    170: "Displacement",
    171: "Torches",
    172: "Oil",
    173: "Speed",
    174: "Tapestry",
    175: "Spine",
    176: "Copper",
    177: "Silver",
    178: "Electrum",
    179: "Gold",
    180: "Platinum",
    181: "Ointment",
    182: "Keoghtum's",
    183: "Sheet",
    184: "Strength",
    185: "Healing",
    186: "Holding",
    187: "Extra",
    188: "Gaseous Form",
    189: "Slipperiness",
    190: "Jewelled",
    191: "Flying",
    192: "Treasure Finding",
    193: "Fear",
    194: "Disappearance",
    195: "Statuette",
    196: "Fungus",
    197: "Chain",
    198: "Pendant",
    199: "Broach",
    200: "of Seeking",
    201: "-1",
    202: "-2",
    203: "-3",
    204: "Lightning Bolt",
    205: "Fire Resistance",
    206: "Magic Missiles",
    207: "Save",
    208: "Clerical Scroll",
    209: "Magic User Scroll",
    210: "With 1 Spell",
    211: "With 2 Spells",
    212: "With 3 Spells",
    213: "Protection Scroll",
    214: "Jewelry",
    215: "Fine",
    216: "Huge",
    217: "Bone",
    218: "Brass",
    219: "Key",
    220: "AC 2",
    221: "AC 6",
    222: "AC 4",
    223: "AC 3",
    224: "of Protection",
    225: "Paralyzation",
    226: "Ogre Power",
    227: "Invisibility",
    228: "Missiles",
    229: "Elvenkind",
    230: "Rotting",
    231: "Covered",
    232: "Efreeti",
    233: "Bottle",
    234: "Missile Attractor",
    235: "of Maglubiyet",
    236: "Secret Door & Trap Detection",
    237: "Gold Dragon Control",
    238: "Feather Falling",
    239: "Giant Strength",
    240: "Restoring Levels",
    241: "Flame Tongue",
    242: "Fireballs",
    243: "Spiritual",
    244: "Boulder",
    245: "Diamond",
    246: "Emerald",
    247: "Opal",
    248: "Sapphire",
    249: "of Tyr",
    250: "of Tempus",
    251: "of Sune",
    252: "Wooden",
    253: "+3 vs Undead",
    254: "Pass",
    255: "Cursed",


}

export const azureItemTypes = {

    0: "",
    1: "Battle Axe",
    4: "Dagger",
    5: "Dart",
    8: "Mace",
    9: "Morning Star",
    12: "Quarrel",
    13: "Scimitar",
    15: "Quarter Staff",
    18: "Long Sword",
    20: "Two-handed sword",
    21: "Trident",
    22: "Composite Long Bow",
    26: "Long Bow",
    27: "Light Crossbow",
    29: "Sling?",
    30: "Arrows",
    31: "Leather",
    35: "Banded",
    36: "Plate",
    37: "Shield",
    38: "Scroll",
    39: "Scroll?",
    42: "Girdle",
    45: "Boots",
    46: "Ring",
    47: "Magical Item",
    50: "Bracers",
    52: "Wand",
    58: "Cloak",
    70: "Flail",
    71: "Halberd",
    72: "Gauntlets",
    78: "Ogre Power",
    90: "of",


}




export const azureSpellList = {
    121: {spellName: "Bless", class: "Cleric", level: 1},
    122: {spellName: "Curse", class: "Cleric", level: 1},
    123: {spellName: "Cure Light Wounds", class: "Cleric", level: 1},
    124: {spellName: "Cause Light Wounds", class: "Cleric", level: 1},
    125: {spellName: "Detect Magic", class: "Cleric", level: 1},
    126: {spellName: "Protection from Evil", class: "Cleric", level: 1},
    127: {spellName: "Protection from Good", class: "Cleric", level: 1},
    128: {spellName: "Resist Cold", class: "Cleric", level: 1},
    129: {spellName: "Burning Hands", class: "Mage", level: 1},
    130: {spellName: "Charm Person", class: "Mage", level: 1},
    131: {spellName: "Detect Magic", class: "Mage", level: 1},
    132: {spellName: "Enlarge", class: "Mage", level: 1},
    133: {spellName: "Reduce", class: "Mage", level: 1},
    134: {spellName: "Friends", class: "Mage", level: 1},
    135: {spellName: "Magic Missile", class: "Mage", level: 1},
    136: {spellName: "Protection from Evil", class: "Mage", level: 1},
    137: {spellName: "Protection from Good", class: "Mage", level: 1},
    138: {spellName: "Read Magic", class: "Mage", level: 1},
    139: {spellName: "Shield", class: "Mage", level: 1},
    140: {spellName: "Shocking Grasp", class: "Mage", level: 1},
    141: {spellName: "Sleep", class: "Mage", level: 1},
    142: {spellName: "Find Traps", class: "Cleric", level: 2},
    143: {spellName: "Hold Person", class: "Cleric", level: 2},
    144: {spellName: "Resist Fire", class: "Cleric", level: 2},
    145: {spellName: "Silence 15' Radius", class: "Cleric", level: 2},
    146: {spellName: "Slow Poison", class: "Cleric", level: 2},
    147: {spellName: "Snake Charm", class: "Cleric", level: 2},
    148: {spellName: "Spiritual Hammer", class: "Cleric", level: 2},
    149: {spellName: "Detect Invisibility", class: "Mage", level: 2},
    150: {spellName: "Invisibility", class: "Mage", level: 2},
    151: {spellName: "Knock", class: "Mage", level: 2},
    152: {spellName: "Mirror Image", class: "Mage", level: 2},
    153: {spellName: "Ray of Enfeeblement", class: "Mage", level: 2},
    154: {spellName: "Stinking Cloud", class: "Mage", level: 2},
    155: {spellName: "Strength", class: "Mage", level: 2},
    156: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    157: {spellName: "Cure Blindness", class: "Cleric", level: 3},
    158: {spellName: "Cause Blindness", class: "Cleric", level: 3},
    159: {spellName: "Cure Disease", class: "Cleric", level: 3},
    160: {spellName: "Cause Disease", class: "Cleric", level: 3},
    161: {spellName: "Dispel Magic", class: "Cleric", level: 3},
    162: {spellName: "Prayer", class: "Cleric", level: 3},
    163: {spellName: "Remove Curse", class: "Cleric", level: 3},
    164: {spellName: "Bestow Curse", class: "Cleric", level: 3},
    165: {spellName: "Blink", class: "Mage", level: 3},
    166: {spellName: "Dispel Magic", class: "Mage", level: 3},
    167: {spellName: "Fireball", class: "Mage", level: 3},
    168: {spellName: "Haste", class: "Mage", level: 3},
    169: {spellName: "Hold Person", class: "Mage", level: 3},
    170: {spellName: "Invisibilty 10' Radius", class: "Mage", level: 3},
    171: {spellName: "Lightning Bolt", class: "Mage", level: 3},
    172: {spellName: "Protection from Evil, 10' Radius", class: "Mage", level: 3},
    173: {spellName: "Protection from Good, 10' Radius", class: "Mage", level: 3},
    174: {spellName: "Protection from Normal Missiles", class: "Mage", level: 3},
    175: {spellName: "Slow", class: "Mage", level: 3},
    176: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    177: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    178: {spellName: "Cure Serious Wounds", class: "Cleric", level: 4},
    179: {spellName: "Spell", class: "Item", level: 0},
    180: {spellName: "Spell", class: "Item", level: 0},
    181: {spellName: "Spell", class: "Item", level: 0},
    182: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    183: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    184: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    185: {spellName: "UNKNOWN", class: "UNKNOWN", level: 0},
    186: {spellName: "Cause Serious Wounds", class: "Cleric", level: 4},
    187: {spellName: "Neutralize Poison", class: "Cleric", level: 4},
    188: {spellName: "Poison", class: "Cleric", level: 4},
    189: {spellName: "Protection from Evil 10'", class: "Cleric", level: 4},
    190: {spellName: "Sticks to Snakes", class: "Cleric", level: 4},
    191: {spellName: "Cure Critial Wounds", class: "Cleric", level: 5},
    192: {spellName: "Cause Critical Wounds", class: "Cleric", level: 5},
    193: {spellName: "Dispel Evil", class: "Cleric", level: 5},
    194: {spellName: "Flame Strike", class: "Cleric", level: 5},
    195: {spellName: "Raise Dead", class: "Cleric", level: 5},
    196: {spellName: "Slay Living", class: "Cleric", level: 5},
    197: {spellName: "Detect Magic", class: "Druid", level: 1},
    198: {spellName: "Entangle", class: "Druid", level: 1},
    199: {spellName: "Faerie Fire", class: "Druid", level: 1},
    200: {spellName: "Invisibility to Animals", class: "Druid", level: 1},
    201: {spellName: "Charm Monsters", class: "Mage", level: 4},
    202: {spellName: "Confusion", class: "Mage", level: 4},
    203: {spellName: "Dimension Door", class: "Mage", level: 4},
    204: {spellName: "Fear", class: "Mage", level: 4},
    205: {spellName: "Fire Shield", class: "Mage", level: 4},
    206: {spellName: "Fumble", class: "Mage", level: 4},
    207: {spellName: "Ice Storm", class: "Cleric", level: 4},
    208: {spellName: "Minor Globe of Invulnerability", class: "Mage", level: 4},
    209: {spellName: "Remove Curse", class: "Mage", level: 4},
    210: {spellName: "Unknown", class: "Unknown", level: 0},
    211: {spellName: "Cloud Kill", class: "Mage", level: 5},
    212: {spellName: "Cone of Cold", class: "Mage", level: 5},
    213: {spellName: "Feeblemind", class: "Mage", level: 5},
    214: {spellName: "Hold Monsters", class: "Mage", level: 5},
    215: {spellName: "Spell", class: "UNKNOWN", level: 0},
    216: {spellName: "Spell", class: "UNKNOWN", level: 0},
    217: {spellName: "Spell", class: "UNKNOWN", level: 0},
    218: {spellName: "Spell", class: "UNKNOWN", level: 0},
    219: {spellName: "Spell", class: "UNKNOWN", level: 0},
    220: {spellName: "Bestow Curse", class: "Mage", level: 4},
}

export const azureClassList ={

    0: "Cleric",
    // 1: "Druid",
    2: "Fighter",
    3: "Paladin",
    4: "Ranger",
    5: "Magic-user",
    6: "Thief",
    // 7: "Monk",
    8: "Cleric/Fighter",
    9: "Cleric/Fighter/Magic-user",
    10: "Cleric/Ranger",
    11: "Cleric/Magic-user",
    // 12: "Cleric/Thief",
    13: "Fighter/Magic-user",
    14: "Fighter/Thief",
    15: "Fighter/Magic-user/Thief",
    16: "Magic-user/Thief",
    17: "Monster",
}